@use "sass:color";

form {
	border-color: rgba($black, get-css-size(opacity, 4));
}
label {
	font-size: $font_0;
	font-weight: $font_bold;
	font-family: $font_ui;
	color: currentColor;
}
%input-font {
	box-sizing: border-box;
	font-size: $font_0;
	font-family: $font_ui;
	color: currentColor;
}
%input-text-base {
	padding: $padding_2 $padding_3;
	line-height: $line-height_3;
	min-height: 1.5em;
	border-color: rgba($black, get-css-size(opacity, 3));
	border-width: thin;
	border-radius: $border_radius;
	
}
%input-focus{
	&:focus {
		box-shadow: inset 1px 1px 2px 2px get-theme-color('primary', 0, CSSVAR,.12), inset 2px 2px 3px 0px get-theme-color('primary', 0, CSSVAR,.1), inset -2px -2px 3px 0px get-theme-color('primary', 0, CSSVAR,.1);
		outline-color:get-theme-color('primary', 0, CSSVAR,.5);
	}
}

%input-expand-to-container {
	width: 100%;
}
input,
select,
textarea{
	@extend %input-focus;
}

input,
select,
textarea,
legend,
fieldset,
button {
	box-sizing: border-box;
	@extend %input-font;
}
select,
textarea {
	@extend %input-text-base;
	@extend %input-expand-to-container;
}

[type='color'] {
	width: 1.5rem;
	height: 1.5rem;
	padding: 0;
	border-color: rgba($black, get-css-size(opacity, 3));
}
[type='radio'],
[type='checkbox'] {
	padding-left: $padding_3;
}
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='email'],
[type='month'],
[type='number'],
[type='password'],
[type='search'],
[type='tel'],
[type='text'],
[type='url'],
[type='week'],
[type='time'] {
	@extend %input-text-base;
	@extend %input-expand-to-container;
	
}

button,
[type='button'],
[type='submit'],
[type='reset'] {
	@extend %input-text-base;
	width: auto;
	border-radius: $border_radius;
}

fieldset {
	padding: $padding_3 $padding_4;
	margin: $margin_3 0;
	border-color: rgba($black, get-css-size(opacity, 2));
}
/*

 Basic Inputs

 Arches is strongly based on utility classes styling everything so it commonly just builds on either the base of the browser defaults or the defaults of the framework that is built on. This is a kitchen sink of html elements so you can see how this variation will effect html. There is No basic styling around the pure DOM with the utility class only.  To get full brand styling use a framework based version of arches.  

  Styleguide HTML.Inputs

*/

/*

  Text Inputs 

  Bellow is the comparison of using the basic Html input types with the utility classes only vs using the bootstrap pattern for the input.

Markup:
<div class="flex_row flex gap-x_5">
	<div class="flex_auto">
		<h3>Utility Class</h3>
		<label for="example-input-email" class='m-b_3'>Email address</label> 
		<input type="email" id="example-input-email" placeholder="Enter email"/>
	</div>
	<div class="flex_auto">
		<h3>Bootstrap</h3>
		<label for="exampleFormControlInput1" class="form-label">Email address</label>
		<input type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com"/>
	</div>
</div>

  Styleguide HTML.Inputs.Text
  
*/


/*

Inputs UC
 
Utility Class only build inputs
 
Markup:
<form>
    <div class="m-b_3"><label class="m-b_3" for="example-input-email">Email address</label> <input type="email" id="example-input-email" placeholder="Enter email"></div>
    <div class="m-b_3"><label class="m-b_3" for="example-input-password1">Number</label> <input type="number" id="example-input-number" placeholder="Number"></div>
    <div class="m-b_3"><label class="m-b_3" for="example-input-password">Password</label> <input type="password" id="example-input-password" placeholder="Password"></div>
    <div class="m-b_3"><label class="m-b_3" for="example-input-search">Search</label> <input type="search" id="example-input-serach" placeholder="Search .."></div>
    <div class="m-b_3"><label class="m-b_3" for="example-input-tel">Telephone number</label> <input type="tel" id="example-input-tel" placeholder="Telephone number"></div>
    <div class="m-b_3"><label class="m-b_3" for="example-input-text">Text</label> <input type="text" id="example-input-text" placeholder="Enter some text here"></div>
    <div class="m-b_3"><label class="m-b_3" for="example-input-url">Url</label> <input type="url" id="example-input-url" placeholder="Enter a url here"></div>
    <div class="m-b_3"><label class="m-b_3" for="example-input-color">Color</label> <input type="color" id="example-inupt-color" placeholder="#fff"></div>
    <div class="m-b_3"><label class="m-b_3" for="example-input-date">Date</label> <input type="date" id="example-input-date" placeholder="date"></div>
    <div class="m-b_3"><label class="m-b_3" for="example-input-date-time">Date / Time</label> <input type="datetime" id="example-input-date-time" placeholder="date / time"></div>
    <div class="m-b_3"><label class="m-b_3" for="example-input-date-time-local">Date / Time local</label> <input type="datetime-local" id="example-input-date-time-local" placeholder="date / time local"></div>
    <div class="m-b_3"><label class="m-b_3" for="example-input-month">Month</label> <input type="month" id="example-input-month" placeholder="Month"></div>
    <div class="m-b_3"><label class="m-b_3" for="example-input-week">Week</label> <input type="week" id="example-input-week" placeholder="Week"></div>
    <div class="m-b_3"><label class="m-b_3" for="example-input-time">Time</label> <input type="time" id="example-input-time" placeholder="Time"></div>
    <div class="m-b_3"><label class="m-b_3" for="example-select1">Example select</label> <select id="example-select1">
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
        </select></div>
    <div><label for="example-select2">Example multiple select</label> <select multiple="" id="example-select2">
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
        </select></div>
    <div><label for="example-textarea">Example textarea</label> <textarea id="example-textarea" rows="3"></textarea></div>
    <div><label for="example-input-file">File input</label> <input type="file" id="example-input-file"></div>
    <fieldset>
        <legend>I am legend</legend>
        <div><label><input type="radio" name="options-radios" id="optionsR-radios1" value="option1" checked=""> Option one is this and that—be sure to include why it's great</label></div>
        <div><label><input type="radio" name="options-radios" id="options-radios2" value="option2"> Option two can be something else and selecting it will deselect option one</label></div>
        <div><label><input type="radio" name="optionsRadios" id="options-radios3" value="option3" disabled=""> Option three is disabled</label></div>
    </fieldset>
    <fieldset>
        <legend>I am also legend</legend><label for="checkbox-demo-1"><input type="checkbox" id="checkbox-demo-1"> Check me out</label> <label for="checkbox-demo-2"><input type="checkbox" id="checkbox-demo-2"> Or check me out</label>
    </fieldset>
    <div>
        <button type="button" name="button">Button</button>
        <input type="button" name="input" value="Input Button">
        <input type="submit" name="submit" value="Submit Button">
        <input type="reset" name="reset" value="Reset Button">
    </div>
</form>
 
Weight:100
 
Styleguide HTML.Inputs.All

*/