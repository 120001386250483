:root {
    --page-padding: #{get-css-size(padding, 3)};
    --outer-content-padding: 0;
    --inner-content-padding: #{get-css-size(padding, 3)};
    --sidebar-width: 100%;
    --content-max-width: calc(100% - (var(--page-padding) * 2));
    --content-min-width: calc(100% - (var(--page-padding) * 2));

    @media #{$medium-up} {
        --inner-content-padding: #{get-css-size(padding, 4)};
        --page-padding: #{get-css-size(padding, 4)};
        --outer-content-padding: 0;
        --sidebar-width: calc(300px + (var(--inner-content-padding) * 2));
    }

    @media #{$large-up} {
        --outer-content-padding: #{get-css-size(padding, 4)};
        --sidebar-width: 300px;
    }
}


[grid-template="page-layout"] {
    margin-bottom: var(--page-padding);
    row-gap: var(--page-padding);
    width: 100%;
    display: grid;
    grid-template-columns:
        [all-start fullscreen-start sidebar-secondary-start sidebar-primary-start] var(--page-padding) 
        [content-start center-start] 1fr 
        [content-end center-end] var(--page-padding) 
        [fullscreen-end all-end sidebar-secondary-end sidebar-primary-end];
    grid-template-rows:
        [fullscreen-start all-start] var(--page-padding) 
        [center-start content-start] auto 
        [center-end content-end sidebar-secondary-start] auto 
        [sidebar-secondary-end sidebar-primary-start] auto 
        [sidebar-primary-end] var(--page-padding) 
        [ fullscreen-end all-end];

    @media #{$medium-up} {
        grid-template-columns:
            [fullscreen-start all-start sidebar-primary-start sidebar-secondary-start] var(--page-padding) 
            [content-start] var(--sidebar-width) 
            [sidebar-primary-end] var(--page-padding) 
            [ center-start] 1fr 
            [center-end content-end] var(--page-padding) 
            [fullscreen-end all-end sidebar-secondary-end];
        grid-template-rows:
            [all-start fullscreen-start content-start center-start sidebar-primary-start] auto 
            [content-end sidebar-secondary-start center-end sidebar-primary-end] auto 
            [sidebar-secondary-end fullscreen-end all-end];
    }

    @media #{$large-up} {
        grid-template-columns:
            [fullscreen-start all-start sidebar-primary-start] var(--page-padding) 
            [content-start] var(--sidebar-width) 
            [sidebar-primary-end] var(--page-padding) 
            [center-start] 1fr 
            [center-end] var(--page-padding) 
            [sidebar-secondary-start] var(--sidebar-width) 
            [content-end] var(--page-padding) 
            [fullscreen-end all-end sidebar-secondary-end];
        grid-template-rows:
            [all-start fullscreen-start content-start center-start sidebar-primary-start sidebar-secondary-start] auto 
            [all-end fullscreen-end content-end center-end sidebar-primary-end sidebar-secondary-end];
    }

    &[template-option="reverse"] {
        @media #{$medium-up} {
            grid-template-columns:
                [fullscreen-start all-start sidebar-secondary-start] var(--page-padding) 
                [content-start center-start] 1fr 
                [center-end] var(--page-padding) 
                [sidebar-primary-start] var(--sidebar-width) 
                [content-end] var(--page-padding) 
                [fullscreen-end all-end sidebar-primary-end sidebar-secondary-end];

        }

        @media #{$large-up} {
            grid-template-columns:
                [fullscreen-start all-start sidebar-secondary-start] var(--page-padding) 
                [content-start] var(--sidebar-width) 
                [sidebar-secondary-end] var(--page-padding) 
                [center-start] 1fr 
                [center-end] var(--page-padding) 
                [sidebar-primary-start] var(--sidebar-width) 
                [content-end] var(--page-padding) 
                [fullscreen-end all-end sidebar-primary-end];
            grid-template-rows:
                [all-start fullscreen-start content-start center-start sidebar-primary-start sidebar-secondary-start] auto 
                [all-end fullscreen-end content-end center-end sidebar-primary-end sidebar-secondary-end];
        }
    }
}


[grid-template="page-layout"] {

    & > [grid-area="fullscreen"],
    & > [grid-area="document"] {
        grid-column: fullscreen;
        grid-row: fullscreen;
    }

    & > [grid-area="sidebar-primary"] {
        grid-column: sidebar-primary;
        grid-row: sidebar-primary;
    }

    & > [grid-area="sidebar-secondary"] {
        grid-column: sidebar-secondary;
        grid-row: sidebar-secondary;
    }
}


[grid-area="content"] {
    grid-column: content;
    grid-row: content;
}

%content-right {
    & > [grid-area="content"] {
        grid-column: center-start / content-end;
        grid-row: content;
    }
}

%content-left {
    & > [grid-area="content"] {
        grid-column: content-start / center-end;
        grid-row: content;
    }
}

%center {
    & [grid-area="content"] {
        grid-column: center;
        grid-row: center;
    }
}


[grid-template="page-layout"]:has(
    [grid-area="sidebar-primary"]),

[grid-template="page-layout"][template-option="reverse"]:has(
    [grid-area="sidebar-secondary"]) {
    @extend %content-right;
}


[grid-template="page-layout"]:has(
    [grid-area="sidebar-secondary"]),

[grid-template="page-layout"][template-option="reverse"]:has(
    [grid-area="sidebar-primary"]) {
    @extend %content-left;
}


[grid-template="page-layout"]:has(
    [grid-area="sidebar-secondary"]):has(
    [grid-area="sidebar-primary"]) {
    @extend %center;

    @media #{$medium-only} {
        
        [grid-area="sidebar-secondary"] {
            grid-column-start: center-start;
        }

        &[template-option="reverse"] 
        [grid-area="sidebar-secondary"] {
            grid-column-end: center-end;
            grid-row-start: all-start;
        }

        
        [grid-area="sidebar-primary"] {
            grid-row-end: sidebar-secondary-end;
        }
    }
}





/*

Page Layout

Descripition:
  <ol>
    <li>
        <strong>Overall Structure:</strong>
        <ul>
        <li>Design the layout to incorporate columns for content and sidebars.</li>
        <li>
        [sidebar]
        [content]
        [sidebar]</li>
        <li>the parent uses `grid-template="page-layout"`</li>
        <li>the children uses `grid-area="content|sidebar-primary|sidebar-secondary"`</li>
        </ul>
    </li>
    <li>
      <strong>Column Configuration:</strong>
      <ul>
        <li>Utilize 'sidebar-primary', 'sidebar-secondary', and 'content' classes to designate columns within the layout.</li>
        <li>Define grid areas in the DOM structure for 'fullscreen', 'sidebar-primary','sidebar-secondary','container'.</li>
      </ul>
    </li>
    <li>
      <strong>Sidebar Specifications:</strong>
      <ul>
        <li>Sidebars are 300px wide with 1 rem padding on the left and right. (300px + 2rem)</li>
        <li>Sidebars do not respect the page margin but adhere to margins between themselves and content.</li>
        <li>Sidebars placed on the edge will extend to the edge of the container.</li>
        </ul>
        <ul>
        <strong>Responsive Design:</strong>
        <li>`Primary sidebar` remains fixed and aligned with the content area. It folds under the content on mobile viewports.</li>
        <li>`Secondary sidebar` remains fixed and shifts below the content on tablet and smaller viewports.</li>
        <li>The sidebar widths are constant when aligned with the content and changes its width to  the above content based on media breakpoints.</li>
      </ul>
    </li>
    <li>
      <strong>Content Area:</strong>
      <ul>
        <li>Allocate remaining space on the page for the content area, respecting a page margin.</li>
      </ul>
    </li>
  </ol>

Markup:
<h4>Document(fullscreen)</h4>
<section grid-template="page-layout" >
    <div grid-area="fullscreen">Document</div>
</section>
<h4>Content No Sidebars</h4>
<section grid-template="page-layout" >
    <div grid-area="content">Content</div>  
</section>
<h4>Content with Primary Sidebar</h4>
<section grid-template="page-layout" >
    <div grid-area="sidebar-primary">sidebar primary</div> 
    <div grid-area="content">content</div> 
</section>
<h4>Content with Primary Sidebar - Reverse <code>template-option="reverse"</code></h4>
<section grid-template="page-layout" template-option="reverse" >
    <div grid-area="sidebar-primary">sidebar primary (reverse)</div> 
    <div grid-area="content">content (reverse)</div> 
</section>
<h4>Content with Secondary Sidebar</h4>
<section grid-template="page-layout" >
    <div grid-area="sidebar-secondary">sidebar secondary</div> 
    <div grid-area="content">content</div> 
</section>
<h4>Content with Secondary Sidebar - Reverse <code>template-option="reverse"</code></h4>
<section grid-template="page-layout" template-option="reverse" >
    <div grid-area="sidebar-secondary">sidebar secondary</div> 
    <div grid-area="content">content</div> 
</section>
<h4>Both Sidebars</h4>
<section grid-template="page-layout" >
    <div grid-area="sidebar-secondary">sidebar secondary</div> 
    <div grid-area="content">content center</div> 
    <div grid-area="sidebar-primary">sidebar primary</div> 
</section>
<h4>Both Sidebars</h4>
<section grid-template="page-layout" template-option="reverse" >
    <div grid-area="sidebar-secondary">sidebar secondary</div> 
    <div grid-area="content">content center</div> 
    <div grid-area="sidebar-primary">sidebar primary</div> 
</section>
{{> "Structures.PageLayout.demoStyles" }}

MarkupWrapperClasses: space-holder colored-zones

Weight: 0

Styleguide Structures.PageLayout
*/


/*

Demo Styles

Descripition:
Styles needed for demo and to not be included in enteprise styles

Markup:
<style>.space-holder section{padding:10px 0; background-color:rgba(128, 128, 128, 0.209); margin-bottom:10px;}
.space-holder div{min-height: 50px; color:rgb(0, 0, 0); padding:10px;}
.colored-zones [grid-area="sidebar-secondary"] {background-color: #0080001b;}
.colored-zones [grid-area="sidebar-primary"] {background-color: #1668ff2d;}
.colored-zones [grid-area="fullscreen"] {background-color:#ff260040;}
.colored-zones [grid-area="content"] {background-color: #ffa6002b;}</style>
</style>

MarkupWrapperClasses: space-holder colored-zones

Weight: 0

Styleguide Structures.PageLayout.demoStyles
*/
