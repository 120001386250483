@import "design-base";
@import "crayonbox";
@import "interactive-extras";
.font {
	.font--example {
		background-image: url(http://basehold.it/i/24);
	}
	.font--name {
		color: #000;
	}

	.font--dropcase_2x {
		color: map-get($theme-palette, primary);
	}
}