/*
Stars
 
Star ratings should be build throughout arches in a more dynamic way but this is an easy level of adding stars. 
 
Markup:
<ul class="ul_none flex flex_inline star-rating">
    <li class="flex_shrink"> <i class="fas fa-star"></i></li>
    <li class="flex_shrink"> <i class="fas fa-star"></i></li>
    <li class="flex_shrink"> <i class="fas fa-star"></i></li>
    <li class="flex_shrink"> <i class="fas fa-star"></i></li>
    <li class="flex_shrink"> <i class="fas fa-star"></i></li>
</ul>
 
Weight:0

caution: This item is not finalized and might be subject to change


 
Styleguide Recipes.InteractiveExtras.Stars
*/

.star-rating{
    color: get-theme-color(secondary, 3, CSSVAR);;
    &.rating-5 li:nth-child(-n+5){ color:get-theme-color(primary, -1, CSSVAR);}
    &.rating-4 li:nth-child(-n+4){ color:get-theme-color(primary, -1, CSSVAR);}
    &.rating-3 li:nth-child(-n+3){ color:get-theme-color(primary, -1, CSSVAR);}
    &.rating-2 li:nth-child(-n+2){ color:get-theme-color(primary, -1, CSSVAR);}
    &.rating-1 li:nth-child(-n+1){ color:get-theme-color(primary, -1, CSSVAR);}
}