.address {
	font-style: unset;
}

@media only screen and (min-width: 640px) {
	.reverse-indent {
		display: inline-block;
		margin-left: 1em;
		padding-right: 1em;
		text-indent: -1em;
		width: 100%;
	}
	.reverse-indent:after {
		content: '';
		display: table;
		clear: both;
	}
}

/*
Generic Footer

This defines the web footer. 

Markup:
<div class="footer">
    <div class="p-t_3 p-b_3 p_4 flex br_0 br-t_3 lh_2 br_solid br_shade-n1 c_shade-1 texture_light bg_shade-1">&nbsp;</div>
    <div class="flex flex_column flex_row:lg justify_between p_4 p_5-large texture_dark" style="">
        <div class="m_auto m-l_0:lg p-l_4:md p-r_5:md">
            <div class="logo_primary-alt  max-w_20"><span class="alt">logo_primary-alt max-w_10</span></div>
            {{> "Recipes.Footer.Address" modifier_class=""}}
            {{> "Recipes.Footer.Social" modifier_class=""}}
        </div>
        <div class="flex_shrink p-l_4:md p-r_4:md w_100 w_auto:md">
            {{> "Recipes.Footer.ACC Product Links" modifier_class=""}}
        </div>
    </div>
    {{> "Recipes.Footer.Subfooter" modifier_class=""}}
    {{> "Recipes.Footer.Cite" }}
</div>

Weight: 40

Styleguide Recipes.Footer
*/


/*
Sub Footer
 
Description: ACC has some basic legal information that should be on every property. 
 
Markup:
<!-- subfooter -->
<div id="subfooter" class="bg_primary c_white font_n1 p-x_4 p-y_4">
    <ul class="block footer-bottom-links ul_none">
        <li class="inline p-r_2 p-y_2"><a href="https://www.acc.org/Footer-Pages/Media-Center"
                    class="c_white-6 h:c_white">Media Center</a></li>
            <li class="inline p_2"><a href="https://www.acc.org/Footer-Pages/Quick-Start-Guide"
                    class="c_white-6 h:c_white">ACC.org Quick Start Guide</a></li>
            <li class="inline p_2"><a href="https://www.acc.org/Footer-Pages/Advertising-and-Sponsorship-Policy"
                    class="c_white-6 h:c_white">Advertising &amp; Sponsorship Policy</a></li>
            <li class="inline p_2"><a href="https://www.acc.org/Footer-Pages/Clinical-Content-Disclaimer"
                    class="c_white-6 h:c_white">Clinical Content Disclaimer</a></li>
            <li class="inline p_2"><a href="https://www.acc.org/Footer-Pages/Editorial-Board"
                    class="c_white-6 h:c_white">Editorial Board</a></li>
            <li class="inline p_2"><a href="https://www.acc.org/Footer-Pages/Privacy-Policy"
                    class="c_white-6 h:c_white">Privacy Policy</a></li>
            <li class="inline p_2"><a href="https://www.acc.org/Footer-Pages/Registered-User-Agreement"
                    class="c_white-6 h:c_white">Registered User Agreement</a></li>
            <li class="inline p_2"><a href="https://www.acc.org/Footer-Pages/Terms-and-Conditions"
                    class="c_white-6 h:c_white">Terms of Service</a></li>
            <li class="inline p_2"><a href="https://www.acc.org/Footer-Pages/Cookie-Policy"
                    class="c_white-6 h:c_white">Cookie Policy</a></li>
            <li class="inline p_2"><a href="https://www.acc.org/sitemap-acc.xml" class="c_white-6 h:c_white">Site
                    Map</a></li>
    </ul>
    <!-- /footer-bottom-links -->
</div>
<!-- /subfooter -->

 
Weight: 0
 
 Styleguide Recipes.Footer.Subfooter
*/
/*
	Cite and Copyright

	Contains copy right 

	Markup:
    <div class="texture_light bg_shade-1 p_4">
        <cite class="c_shade-1 font_italic font_bold font_n2 p_2 lh_2">&copy; 2023 American College of Cardiology Foundation. All rights reserved.</cite>
    </div>
	

	Styleguide Recipes.Footer.Cite

*/

/*
Address

Address and contact information for Heart House

Markup:
<section class="address c_white">
    <h3 class="font_accent font_1 m-b_0 font_normal font_regular">American College of Cardiology</h3>
    <address>
        <!-- http://microformats.org/wiki/hcard -->
        <h4 class="m-b_0 font_bold font_copy font_normal font_1 m-t_4">Heart House</h4>
        <div class="flex flex_column flex_row:md font_0 font_copy gap-x_5">
        <div class="flex_auto">
            <ul class="ul_none lh_0">
                <li class="m-b_1 m-b_3:md font_normal">
                    <span class="street-address">2400 N St. NW</span>
                </li>
                <li class="m-b_1 m-b_3:md font_normal">
                    <span class="locality ">Washington</span>,
                    <span class="city-name ">
                <abbr title="District of Colombia">DC</abbr>
                </span>
                    <span class="postal-code">20037</span>
                </li>
                <li class="m-b_1 m-b_3:md font_normal">
                    <a href="mailto:MemberCare@acc.org" class="email c_white text_underline  h:c_white ">
                        <strong class="p-r_2 inline block:md inline:lg">Email:</strong>
                        <span class="inline block:md inline:lg">membercare@acc.org</span></a>
                </li>
            </ul>
        </div>
        <div class="flex_auto">
        <ul class="ul_none lh_0">
            <li class="m-b_1 m-b_3:md font_normal">
                <a tel="+12023756000" href="tel:+12023756000" class="tel c_white h:c_white h:underline">
                <strong class="p-r_2 block inline:lg">Phone:</strong><span class=" block inline:lg">1-202-375-6000</span></a>
            </li>
            <li class="m-b_1 m-b_3:md font_normal">
                <a tel="+18002534636" href="tel:+18002534636" class="tel c_white h:c_white h:underline">
                <strong class="p-r_2 block inline:lg">Toll Free:</strong><span class=" block inline:lg">1-800-253-4636</span></a>
            </li>
            <li class="m-b_1 m-b_3:md font_normal">
                <a fax="+12023757000" href="fax:+12023757000" class="fax c_white h:c_white h:underline">
                <strong class="p-r_2 block inline:lg">Fax:</strong>
                <span class="block inline:lg">1202-375-7000</span>
                </a>
            </li>
        </ul>
        </div>
        </div>
    </address>
</section>

MarkupWrapperClasses:
    texture_dark p_4 max-w_30

Styleguide Recipes.Footer.Address

*/

/*
Fat Footers Links

Footers in the ACC sometimes have fat footers with a list of categories and links. 

Markup:
<section class="flex_auto p-x_3 p-y_4:lg w_1-third:lg w_50:md font_ui is-active_toggle-function">
    <h2 class="font_2 font_3:lg font_display p-t_2 m-b_0:md m-b_2 br_none:md ">
        <span class="toggle display_none:md ">
        <i class="fas fa-minus-square c_white-8 p-r_4"></i></span>
        <a class="c_accent h:c_secondary-3" href="#">Conditions &amp; Topics</a>
    </h2>
    <ul class="block:md display_none font_0:lg font_n1 m-b_4 ul_none a:_block">
        <li class="text-indent_n1 p-l_4">
            <a class="c_white h:c_secondary-3 h:underline" href="foo.html">Angina</a>
        </li>
        <li class="text-indent_n1 p-l_4">
            <a class="c_white h:c_secondary-3 h:underline" href="foo.html">Aortic Aneurysm</a>
        </li>
        <li class="text-indent_n1 p-l_4">
            <a class="c_white h:c_secondary-3 h:underline" href="foo.html">Atrial Fibrillation</a>
        </li>
        <li class="text-indent_n1 p-l_4">
            <a class="c_white h:c_secondary-3 h:underline" href="foo.html">Bradycardia</a>
        </li>
    </ul>
</section>

MarkupWrapperClasses:
    texture_dark p_4


Styleguide Recipes.Footer.Fat Footer-Links

*/

/*
ACC Product Links

Links to the other major products in the ACC portfolio 

Markup:
<!-- ACC Product Links -->
<section class="acc-websites flex_auto">
        <ul class="ul_none lh_2 font_0">
            <li class="c_white">
                <a target="_blank" class="c_white flex flex_row h:bg_black-2 h:c_white justify_between p-x_2 p_3" href="https://www.jacc.org/">
                    <span class="flex_shrink inline-block p-r_4:lg p-r_3 p-l_3">ACC.org</span>
                    <i class="c_white m-l_auto fa-external-link-square fas flex_shrink float_right h:c_white p-r_3"></i>
                </a>
            </li>
            <li class="c_white br_solid br_0 br-t_1 br_white-5">
                <a target="_blank" class="c_white flex flex_row h:bg_black-2 h:c_white justify_between p-x_2 p_3" href="https://www.jacc.org/">
                    <span class="flex_shrink inline-block p-r_4:lg p-r_3 p-l_3">JACC Journals</span>
                    <i class="c_white m-l_auto fa-external-link-square fas flex_shrink float_right h:c_white p-r_3"></i>
                </a>
            </li>            
            <li class="c_white br_solid br_0 br-t_1 br_white-5">
                <a target="_blank" class="c_white flex flex_row h:bg_black-2 h:c_white justify_between p-x_2 p_3" href="http://cvquality.acc.org/">
                    <span class="flex_shrink inline-block p-r_4:lg p-r_3 p-l_3">Quality Improvement for Institutions</span>
                    <i class="c_white m-l_auto fa-external-link-square fas flex_shrink float_right h:c_white p-r_3"></i>
                </a>
            </li>
            <li class="c_white br_solid br_0 br-t_1  br_white-5">
                <a target="_blank" class="c_white flex flex_row h:bg_black-2 h:c_white justify_between p-x_2 p_3" href="http://cvquality.acc.org/NCDR-Home">
                    <span class="flex_shrink inline-block p-r_4:lg p-r_3 p-l_3">NCDR</span>
                    <i class="c_white m-l_auto fa-external-link-square fas flex_shrink float_right h:c_white p-r_3"></i>
                </a>
            </li>
            <li class="c_white br_solid br_0 br-t_1  br_white-5">
                <a target="_blank" class="c_white flex flex_row h:bg_black-2 h:c_white justify_between p-x_2 p_3" href="https://cvquality.acc.org/accreditation">
                    <span class="flex_shrink inline-block p-r_4:lg p-r_3 p-l_3">ACC Accreditation Services</span>
                    <i class="c_white m-l_auto fa-external-link-square fas flex_shrink float_right h:c_white p-r_3"></i>
                </a>
            </li>
            <li class="c_white br_solid br_0 br-t_1  br_white-5">
                <a target="_blank" class="c_white flex flex_row h:bg_black-2 h:c_white justify_between p-x_2 p_3" href="https://www.cardiosmart.org/For-Clinicians">
                    <span class="flex_shrink inline-block p-r_4:lg p-r_3 p-l_3">CardioSmart</span>
                    <i class="c_white m-l_auto fa-external-link-square fas flex_shrink float_right h:c_white p-r_3"></i>
                </a>
            </li>
            <li class="c_white br_solid br_0 br-t_1  br_white-5">
                <a target="_blank" class="c_white flex flex_row h:bg_black-2 h:c_white justify_between p-x_2 p_3" href="https://www.medaxiom.com/">
                    <span class="flex_shrink inline-block p-r_4:lg p-r_3 p-l_3">MedAxiom</span>
                    <i class="c_white m-l_auto fa-external-link-square fas flex_shrink float_right h:c_white p-r_3"></i>
                </a>
            </li>
            <li class="c_white br_solid br_0 br-t_1  br_white-5">
                <a target="_blank" class="c_white flex flex_row h:bg_black-2 h:c_white justify_between p-x_2 p_3" href="https://www.acc.org/About-ACC/Contact-Us">
                    <span class="flex_shrink inline-block p-r_4:lg p-r_3 p-l_3">Contact Us</span>
                    <i class="c_white m-l_auto fa-external-link-square fas flex_shrink float_right h:c_white p-r_3"></i>
                </a>
            </li>
        </ul>
    </section>
<!-- /ACC Product Links -->

MarkupWrapperClasses:
texture_dark p_4

Styleguide Recipes.Footer.ACC Product Links

*/
/*
Social Links

The college has a collection of social or secondary platforms we interact with.

Markup:
<!-- Social -->
<h4 class="c_white font_regular font_accent m-b_3 m-b_4:lg text_center text_left:lg">Stay Connected
</h4>
<ul class="flex social-bar ul_none c_white h:c_white m_0">
    <li class="flex_auto m-r_2"><a href="http://www.youtube.com/user/ACCinTouch" target="_blank" class="bg_youtube social-bar--button block m-b_0 p_3 c_white-8 h:c_white text_center h:bg_primary-n1"><i class="fab fa-youtube"></i></a></li>
    <li class="flex_auto m-r_2"><a href="https://www.linkedin.com/company/american-college-of-cardiology/" target="_blank" class="bg_linkedin social-bar--button block m-b_0 p_3 c_white-8 h:c_white text_center h:bg_primary-n1"><i class="fab fa-linkedin-in"></i></a></li>
    <li class="flex_auto m-r_2"><a href="https://www.facebook.com/AmericanCollegeofCardiology" target="_blank" class="bg_facebook social-bar--button block m-b_0 p_3 c_white-8 h:c_white text_center h:bg_primary-n1"><i class="fab fa-facebook-square"></i></a></li>
    <li class="flex_auto m-r_2"><a href="https://twitter.com/accintouch" target="_blank" class="bg_twitter social-bar--button block m-b_0 p_3 c_white-8 h:c_white text_center h:bg_primary-n1"><i class="fab fa-square-x-twitter"></i></a></li>
    <li class="flex_auto m-r_2"><a href="https://www.instagram.com/americancollegeofcardiology" target="_blank" class="bg_instagram social-bar--button block m-b_0 p_3 c_white-8 h:c_white text_center h:bg_primary-n1"><i class="fab fa-instagram"></i></a></li>
    <!--<li class="flex_auto m-r_2"><a href="https://play.google.com/store/apps/dev?id=5189912010956845696&amp;hl=en_US" target="_blank" class="bg_google-play social-bar--button block m-b_0 p_3 c_white-8 h:c_white text_center h:bg_primary-n1"><i class="fab fa-google-play"></i></a></li>
    <li class="flex_auto m-r_2"><a href="https://apps.apple.com/us/developer/american-college-of-cardiology/id502174543#see-all/i-phonei-pad-apps" target="_blank" class="bg_apple-store social-bar--button block m-b_0 p_3 c_white-8 h:c_white text_center h:bg_primary-n1"><i class="fab fa-apple"></i></a></li>-->
</ul>
<!-- Social -->

MarkupWrapperClasses:
texture_dark p_4 max-w_40

Styleguide Recipes.Footer.Social

*/
