[grid-template]{
  display:grid;
}
[grid-template="hero-image-cta"]{
  --container-padding:  #{get-css-size(padding, 3)}; 
  --hero-overlay-color: var(--acc);
  --hero-overlay-opacity:.85;
  --breadcrumb-height: 2.5rem;
  grid-template-columns: 
    [all-start breadcrumb-start title-start] var(--container-padding, 0) 
    [content-start cta-start ] 1fr 
    [ cta-end title-end content-end] var(--container-padding, 0) 
    [all-end breadcrumb-end];
  grid-template-rows:
      [all-start breadcrumb-start] minmax(var(--breadcrumb-height, 2.5rem), min-content)
      [breadcrumb-end ] var(--container-padding, 0)
      [title-start content-start] auto 
      [title-end] calc( var(--container-padding, 0)* .5)
      [cta-start] auto
      [cta-end content-end] var(--container-padding, 0)
      [all-end];
  @media #{$medium-up-cq} {
    --container-padding:  #{get-css-size(padding, 4)};
    // rows_2:md columns_4:md --row-1:2.5rem; --row-2:min-content  
    grid-template-rows: 
      [all-start breadcrumb-start] var(--breadcrumb-height, 2.5rem) 
      [breadcrumb-end] calc( var(--container-padding, 0)* .5)
      [title-start cta-start content-start] auto 
      [title-end cta-end content-end] var(--container-padding, 0) 
      [all-end];
    grid-template-columns: 
      [all-start breadcrumb-start title-start] var(--container-padding, 0)
      [content-start ] 1fr
      [title-end] var(--container-padding, 0)
      [cta-start] 1fr 
      [cta-end content-end] var(--container-padding, 0)
      [all-end breadcrumb-end];
  }
  @media #{$large-up-cq} {
    --container-padding:  #{get-css-size(padding, 5)};  
    grid-template-columns: 
    [all-start breadcrumb-start title-start]  var(--container-padding, 0)
    [content-start ] 3fr
    [title-end] 1fr
    [cta-start] 3fr
    [cta-end content-end] var(--container-padding, 0) 
    [all-end breadcrumb-end];
    // class="hero relative isolation_isolate grid rows_3 rows_2:md columns_4:md columns_5:lg columns_2 m-t_n4 m-x_n4  overflow_hidden " style="--hero-overlay-opacity:.9; --row-1:2.5rem; --row-2:min-content;"
    
  }
  &[template-option="micro"]{
    grid-template-columns: 
    [all-start title-start] var(--container-padding, 0) 
    [content-start cta-start ] 1fr 
    [cta-end title-end content-end] var(--container-padding, 0) 
    [all-end];
    grid-template-rows:
        [all-start] var(--container-padding, 0)
        [title-start content-start] auto 
        [title-end] calc( var(--container-padding, 0)* .5)
        [cta-start] auto
        [cta-end content-end] var(--container-padding, 0)
        [all-end];
        @media #{$medium-up-cq} { 
          grid-template-rows: 
            [all-start] calc( var(--container-padding, 0)* .5)
            [title-start cta-start content-start] auto 
            [title-end cta-end content-end] calc( var(--container-padding, 0)* .5)
            [all-end];
          grid-template-columns: 
            [all-start title-start] var(--container-padding, 0)
            [content-start ] 1fr
            [title-end] var(--container-padding, 0)
            [cta-start] min-content
            [cta-end content-end] var(--container-padding, 0)
            [all-end ];
        }
        @media #{$large-up-cq} { 
          grid-template-columns: 
            [all-start title-start] var(--container-padding, 0)
            [content-start ] 1fr
            [title-end] var(--container-padding, 0)
            [cta-start] min-content
            [cta-end content-end] var(--container-padding, 0)
            [all-end ];
        }
    }
}
[grid-template=hero-image-cta]{
  overflow: clip;
  isolation: isolate;
  [grid-area]{z-index: 1;}
  [grid-area="background"]{
    grid-row: all;
    grid-column: all;
    z-index: -1;
  }
  [grid-area="breadcrumb"]
  {
    grid-row: breadcrumb;
    grid-column: breadcrumb;

  }
  [grid-area="title"]{

    grid-row: title;
    grid-column: title;
  }
  [grid-area="cta"]{
    grid-row: cta;
    grid-column: cta;
  }
}


%after-background-transparency{
  position: relative;
  isolation: isolate;
  &::after{
    content:'';
    background-color: var(--hero-overlay-color);
    opacity: var(--hero-overlay-opacity);
    background-blend-mode: multiply;
    mix-blend-mode: multiply;
    inset:0px;
    position: absolute;
    z-index: -1;
  }
}

[data-item="hero-image-breadcrumb-nav"],[data-item="cta-callout"],[data-item="hero-title"] span{
  @extend %after-background-transparency;
}

[data-item="hero-title"]{
  display:flex;
  flex-wrap: wrap;
  flex-direction:row;
  padding-inline-start: 1rem;
  overflow:clip;

  &::before{
      content:'';
      background-color: var(--hero-overlay-color);
      opacity: var(--hero-overlay-opacity);
      background-blend-mode: multiply;
      inset:0 auto 0 -1rem;
      position: absolute;
      z-index: -1;
      width: 2rem;
  }
  span{
      flex: 0 1 auto;
      color: inherit;
      padding-block: 0.125em;
      padding-inline: 0 0.25em;
      position: relative;
      isolation: isolate;
      text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  }
}

[data-item="hero-image-breadcrumb-nav"]::after{
  border-end-end-radius: $border_radius;
}
[data-item="cta-callout"]::after{
  border-radius:  $border_radius;
}


/*
MicroSite 

<div class="reading-typography"><p>A MicroSite <strong>(Conversion Focused Sub-Section)</strong>, exists within a the larger website, but offers a strategic approach for encompassing a semi-independent initiative of the organization, all while retaining a robust link to the main site. While it upholds most of the branding guidelines of the main site, a MicroSite has its unique design, acting as a concentrated unit for user context.
</p><p>The creation of a MicroSite enables businesses to effectively spotlight specific campaigns or products with a distinctive touch, cater to particular audience segments, and maintain a uniform user experience. Despite living relatively independently from the main site's structure and supplementary content, it still provides fluid navigation and integration with the primary website.
</p><p>This arrangement presents a flexible and specialized platform for delivering customized messaging, engaging users in a personalized way, and accomplishing specific objectives that may necessitate a unique online presence.
</p><h4>A Microsite Must</h4><ul><li>Have a user goal</li><li>Have a call to action for conversion.</li><li>Have a focused audience so the voice can be taylored to the action.</li><ul></div>

Styleguide Component.MicroSite

*/

/*
Hero Image with CTA


The "Hero Image with CTA" component signifies a hero section that includes a superimposed call-to-action. This element typically combines a return feature to the main site and should be exclusively used on microsites.

Markup:
<div grid-template="hero-image-cta" id="hero" class="font_3:lg font_2:md font_1">
    <div grid-area="background" class="col_all overflow_hidden row_all">    
      {{> "Component.MicroSite.Sub.HeroImage.BackgroundImage" modifier_class="" }}
    </div>
    <div grid-area="breadcrumb" class="z_1 flex flex_row justify_start">
        {{> "Component.MicroSite.Sub.HeroImage.BreadcrumbNav" modifier_class="font-size_down-2" }}
    </div>
    <div grid-area="title" class="relative font-size_up-2 m-y_6:lg m-y_5:md">
        {{> "Component.MicroSite.Sub.PageTitleTreatment" modifier_class="font-size_up-2 lh_1 m_0" }}
    </div>
    <div grid-area="cta" class="relative font-size_down-1 grid justify_center items_center">
        {{> "Component.MicroSite.Sub.HeroImage.CTAHighlight" modifier_class="z_1 font-size_0" }}
    </div>
</div>

MarkupWrapperClasses: wrapper-container

SubComponents:
Component.MicroSite.Sub.HeroImage.BackgroundImage - Hero Background Image
Component.MicroSite.Sub.HeroImage.BreadcrumbNav - Hero Breadcrumb Navigation
Component.MicroSite.Sub.PageTitleTreatment - Page Title Treatment
Component.MicroSite.Sub.HeroImage.CTAHighlight - CTA Highlight

Weight: 0

NOTE: The Hero Image with CTA component is used to create impactful hero sections with a compelling call-to-action overlay. It combines a visually appealing hero image with a title, description, and call-to-action button.



Styleguide Component.MicroSite.HeroImageWithCTA

*/

/*
Micro Branding with CTA Button


The "Hero Image with CTA" component signifies a hero section that includes a superimposed call-to-action. This element typically combines a return feature to the main site and should be exclusively used on microsites.

Markup:
<div grid-template="hero-image-cta" template-option="micro" class="isolation_isolate overflow_hidden relative"> 
  {{> "Component.MicroSite.Sub.HeroImage.BackgroundImage" }}
  <div grid-area="title">
    {{> "Component.MicroSite.Sub.PageTitleTreatmentMicro" modifier_class="lh_3 m_0 m-y_3 m-y_0:lg" }}
    </div>
    <div grid-area="cta" class="relative font-size_down-1 grid justify_center items_center">
      {{> "Component.MicroSite.Sub.HeroImage.CTAButton" modifier_class=""}}
  </div>
</div>

MarkupWrapperClasses: wrapper-container

SubComponents:
Component.MicroSite.Sub.HeroImage.BackgroundImage - Background Image
Component.MicroSite.Sub.PageTitleTreatment - Page Title
Component.MicroSite.Sub.HeroImage.CTAButton - CTA Button

Weight: 0

NOTE: Micro Branding is used to create cohesive experiance by continuing the branding to children pages of a microiste. It combines a slice of hero image with just the single call-to-action button.



Styleguide Component.MicroSite.MicroBrandingWithButtton

*/

/*
CTA Highlight

The CTA Highlight component is used to create a highlighted call-to-action section.

Modifier: inverted - This modifier class is used to create an inverted version of the CTA Highlight component, which can be applied when a dark background is present.

Markup:
<div data-item="cta-callout" class="{{modifier_class}} c_white color_inherit font-size_0 isolation_isolate p_4 p_5:lg relative z_1">
      <h2 data-item="cta-title" class="font_medium m-t_0 m-b_3 font_display font-size_up-2">Call to Action Title (CTA). You Should be Engaged</h2>
      <p data-item="cta-description" class="font-size_up-0 lh_3">CTA reasoning background or message to convince a user to engage with the goal of the page and interact with the CTA Button. Officiis eum nemo id minus ut itaque et et beatae. Voluptas est incidunt.</p>
      {{> Component.MicroSite.Sub.HeroImage.CTAButton  modifier_class=""}}
</div>


MarkupWrapperClasses: 

Weight: 0

NOTE: The CTA Highlight component is effective for drawing attention to important calls-to-action on a website or application. When using the 'inverted' modifier class, it provides a visually striking effect when placed on a dark background.


Styleguide  Component.MicroSite.Sub.HeroImage.CTAHighlight

*/

/*
CTA Button

The CTA Button is just a centered button for the highlighted call-to-action.


Markup:
<div class="grid justify_center items_center {{modifier_class}}">
  <button class="br_white-3 btn btn-primary c_white expanded-click-area shadow_overlap-bold">CTA ACTION LABEL</button>
</div>

MarkupWrapperClasses: 

Weight: 0

NOTE: The CTA Highlight component is effective for drawing attention to important calls-to-action on a website or application. When using the 'inverted' modifier class, it provides a visually striking effect when placed on a dark background.


Styleguide  Component.MicroSite.Sub.HeroImage.CTAButton 

*/

/*
Hero Image Breadcrumb Nav

The Hero Image Breadcrumb Nav is a sub-component used within the Hero Image with CTA component to display a breadcrumb navigation on top of the hero image.

Markup:
<nav data-item="hero-image-breadcrumb-nav" class="{{modifier_class}} relative isolation_isolate p-y_3 p-x_4 c_white-8">
	<a href="#" class="h:c_white h:underline font_bold c_white-8 expanded-click-area h:c_white"><i class="fas fa-arrow-left"></i> Back</a> |
	The Parent of This Micro Section of the Site</a>
</nav>

MarkupWrapperClasses: 

Weight: 0

NOTE: The Hero Image Breadcrumb Nav sub-component is used to provide breadcrumb navigation on top of the hero image within the Hero Image with CTA component. It focuses on displaying the immediate parent only in the breadcrumb trail.


Styleguide  Component.MicroSite.Sub.HeroImage.BreadcrumbNav

*/


/*
Page Title Treatment

MicroSite Page Title Treatment component is used to style and emphasize page titles.

Modifier: font-color - This modifier class is used to customize the font color of the page title.

Markup:
<h1 data-item="hero-title"
  class="{{modifier_class}} c_white isolation_isolate relative">
  <span class="text">Hero</span>
  <span class="text">Statement</span>
  <span class="text">Mision</span>
  <span class="text">of </span>
  <span class="text">the</span>
  <span class="text">Page</span>
</h1>


MarkupWrapperClasses: bg_hf-2 p_5 hero max-w_40

Weight: 0

NOTE: The Page Title Treatment component is ideal for styling and emphasizing page titles. The "font-color" modifier can be applied to customize the font color of the page title. This component is commonly used in MicroSites to provide a special impact and lightly brand them as a contained experience.


Styleguide   Component.MicroSite.Sub.PageTitleTreatment

*/
/*
Page Title Treatment Micro

Micro MicroSite Page Title Treatment has a back button in mobile.

Markup:
<h1 data-item="hero-title"
  class="{{modifier_class}} c_white isolation_isolate relative">
  <span class="display_none:md"><a href="#RootPage" class="h:c_white h:underline font_bold c_white-8 expanded-click-area h:c_white"><i class="fas fa-solid fa-arrow-left m-l_n3" aria-hidden="true"></i></a></span>
  <span class="text">Hero</span>
  <span class="text">Statement</span>
  <span class="text">Mision</span>
  <span class="text">of </span>
  <span class="text">the</span>
  <span class="text">Page</span>
</h1>


MarkupWrapperClasses: bg_hf-2 p_5 hero max-w_40

Weight: 0

NOTE: The Page Title Treatment component is ideal for styling and emphasizing page titles. The "font-color" modifier can be applied to customize the font color of the page title. This component is commonly used in MicroSites to provide a special impact and lightly brand them as a contained experience.


Styleguide   Component.MicroSite.Sub.PageTitleTreatmentMicro

*/



/*
Responsive Hero Image

The Responsive Hero Image component is used to display a responsive hero image that adapts to different screen sizes. the sizes are 1200/300, 1024/256,600/300, & 320/300


Markup:
  <picture data-item="responsive-hero-image" class="absolute bg_cover flex flex_column inset_0 {{modifier_class}}">
    <source srcset="https://acc-style.github.io/PrototypingACCorg/assets/images/ACC/Join/1200x300.png"
      media="(min-width: 1024px)" class="display_none">
    <source srcset="https://acc-style.github.io/PrototypingACCorg/assets/images/ACC/Join/1024x256.png"
      media="(min-width: 768px)" class="display_none">
    <source srcset="https://acc-style.github.io/PrototypingACCorg/assets/images/ACC/Join/600x300.png"
      media="(min-width: 400px)" class="display_none">
    <img src="https://acc-style.github.io/PrototypingACCorg/assets/images/ACC/Join/320x450.png" alt="Hero Image"
      class="flex_100">
  </picture>

MarkupWrapperClasses: columns_1 grid relative rows_1 w_100 overflow_hidden p_6

Weight: 0

NOTE: It utilizes the "Lorem Picsum" service for placeholder images. Replace all 4 images for best results.


Styleguide Component.MicroSite.Sub.HeroImage.BackgroundImage


*/

/*
Page Break with Headline

This component represents a page break with a headline. It can be used to visually separate sections of a page or document. These Page Breaks are semanticaly an h2 with decorations. This shouldn't be used with other levels of Headlines. 

Markup Example:

<h2 class="c_primary-n1 flex flex_column flex_row:md font_display font_medium m_0 p-y_5:md p-y_4">
  <span class="flex_auto grid">
    <span class="br-t_1 br-tl_radius br_inherit br_solid self_center"></span>
  </span>
  <span class="flex_auto flex_shrink p-x_4 p-x_5:lg lh_1 text_center">
    Users are the Center of it All
  </span>
  <span class="flex_auto grid">
    <span class="br_primary-1 br-tl_radius br-t_1 br_solid br_inherit self_center"></span>
  </span>
</h2>


Note: These are styled heavier then a normal h2 and there are for eaiser understanding that this is a sperator of major content areas.

MarkupWrapperClasses: p_5 c_whtie

Weight: 100


Styleguide: Component.MicroSite.PageBreakHeadline
*/


