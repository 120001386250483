/*
Carousel ALT

A slide-show component for cycling through groups of images and text.
.no-controls            Will remove left and right arrow
.text-indicators        Converts the circle indicators to text tabs that lock to the right side of the images.
.chevron-on             Adds a light orange chevron on the '.active' indicator to increase its impact.


Markup:
<div
	id="carouselExampleFade"
	class="carousel carousel-alt slide no-controls text-indicators chevron-on"
>
	<ol class="carousel-indicators">
		<li data-target="#carouselExampleFade" data-slide-to="0" class="wrap" >
			quaerat odit assumenda
		</li>
		<li data-target="#carouselExampleFade" data-slide-to="1" class=" wrap">
			delectus sunt optio
		</li>
		<li data-target="#carouselExampleFade" data-slide-to="2" class=" wrap">
			deserunt quia quasi
		</li>
	</ol>
	<!-- Wrapper for slides -->
	<div class="carousel-inner" role="listbox">
		<div class="carousel-item active">
			<a><img class="block w_100" src="http://lorempixel.com/630/420/animals" alt="..."/></a>
			<div class="carousel-caption bg_black-7 text-shadow_black-1 text_center text_left:md font_2:md c_white p_3:lg p-b_4:lg p-y_3 lh_1 b_0 r_0 l_0">
                    <span class="title  font_display block m-b_2 font_4:md m-b_3:md">Cardiac Syncope Program Launches!</span>
                    <p><strong>NEW</strong>&nbsp;|&nbsp;Hear from an expert panel and complete four patient cases to test your knowledge on our Syncope Program! <a href="http://edu.acc.org/diweb/catalog/item/eid/SYNCOPE2019" title="" class="" target="_blank"><strong>Find out more!</strong></a></p>
                </div>
		</div>
		<div class="carousel-item">
			<a><img class="block w_100" src="http://lorempixel.com/630/420/nature" alt="..."/></a>
			<div class="carousel-caption bg_black-7 text-shadow_black-1 text_center text_left:md font_2:md c_white p_3:lg p-b_4:lg p-y_3 lh_1 b_0 r_0 l_0">
				<span class="title font_display block m-b_2 font_4:md m-b_3:md">sint explicabo sequi</span> Earum nihil omnis.
			</div>
		</div>
		<div class="carousel-item">
			<a><img class="block w_100" src="http://lorempixel.com/630/420/food" alt="..."/></a>
			<div class="carousel-caption bg_black-7 text-shadow_black-1 text_center text_left:md font_2:md c_white p_3:lg p-b_4:lg p-y_3 lh_1 b_0 r_0 l_0">
				<span class="title font_display block m-b_2 font_4:md m-b_3:md">sint explicabo sequi</span> Earum nihil omnis.
			</div>
		</div>
	</div>
	<!-- Controls -->
	<a
		class="left carousel-control-prev"
		href="#carouselExampleFade"
		role="button"
		data-slide="prev"
	>
		<i class="fas fa-chevron-left"></i>
		<span class="sr-only">Previous</span>
	</a>
	<a
		class="right carousel-control-next"
		href="#carouselExampleFade"
		role="button"
		data-slide="next"
	>
		<i class="fas fa-chevron-right"></i>
		<span class="sr-only">Next</span>
	</a>
</div>


Styleguide  Bootstrap.Carousel

*/
@use "sass:math";

$carousel-control-font-size: ms(1);
.carousel-alt {
	max-width: 630px;
	position: relative;
	background: url("http://cdn.backgroundhost.com/backgrounds/subtlepatterns/subtle_surface.png");
	background-repeat: repeat;
	margin: 0 auto 1rem;

	&:before {
		display: block;
		content: "";
		width: 100%;
		padding-top: math.div(2, 3) * 100%;
	}

	> .carousel-inner {
		position: absolute;
		top: 0px;
		right: 0;
		bottom: 0;
		left: 0;
	}

	.carousel-indicators {
		background-color: rgba(0, 0, 0, 0.6);
		bottom: auto;
		width: 100%;
		top: 0;
		left: 0;
		right: 0;
		margin: 0;

		li {
			text-indent: -9999px;
			overflow: hidden;
			border: none;
			border-radius: $global-border-round;
			box-sizing: content-box;
			width: 12px;
			height: 12px;
			margin-top: 5px;
			margin-bottom: 5px;
			&.active {
				border-color: get-theme-color(accent,0,CSSVAR);
				background-color: get-theme-color(accent,0,CSSVAR);
			}
		}
	}

	.carousel-caption {
	}
	&.chevron-on .carousel-indicators li.active:before {
		@if($usingFontAwesome){
		@extend %font-awesome-pro !optional;
		content: "\f054";
		}
		color: get-theme-color(accent,0,CSSVAR);
		font-size: ms(4);
		padding-top: 5px;
		top: 4px;
		left: 4px;
	}

	&.text-indicators {
		@media #{$medium-up} {
			max-width: 900px;

			&:before {
				padding-top: math.div(7, 15) * 100%;
			}

			.carousel-inner {
				width: 70%;

				> .carousel-item {
					width: 100%;
					height: 100%;
					overflow: hidden;
					&:before {
						display: inline-block;
						/* width: 100%; */
						width: 1px;
						margin-left: -1px;
						padding-top: 66.6666666667%;
						content: "";
					}
					a {
						float: left;
					}
				}
			}

			a[class^="carousel-control"].right {
				right: 30%;
			}
			.carousel-indicators {
				display: flex;
				flex-direction: column;
				height: 100%;
				bottom: 0;
				top: 0;
				right: 0;
				max-width: 300px;
				width: 30%;
				left: auto;

				li {
					width: auto;
					flex: 1 1 20%;
					text-indent: 0;
					border-radius: 0;
					background-color: get-theme-color(shade, 4,CSSVAR);
					display: block;
					position: relative;

					height: auto;
					padding: $padding_3 $padding_4;
					flex-direction: column;
					transition: background-color 0.25s ease-out;
					-moz-transition: background-color 0.25s ease-out;
					-webkit-transition: background-color 0.25s ease-out;
					border: 1px solid get-theme-color(shade, 3,CSSVAR);
					text-align: left;
					font-size: ms(1);
					margin: 0;
					&:first-child {
						border-top: 0;
					}

					&:last-child {
						border-bottom: 0;
					}

					&.active {
						background-color: get-theme-color(shade, -1, CSSVAR);
						color: white;
						border-right: get-theme-color(accent, 0, CSSVAR) 10px solid;
						padding-right: 10px;
					}
				}
			}
		}
	}
}
.carousel-control-prev,
.carousel-control-next {
	.no-controls & {
		@media #{$medium-up} {
			display: none;
		}
	}
	min-width: 3rem;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 20%;
	font-size: $carousel-control-font-size;
	text-align: center;
	background-color: rgba(0, 0, 0, 0);
	@media #{$medium-up} {
		width: 10%;
	}
	@media #{$large-up} {
		width: 5%;
	}
	// Hover/focus state
	&:hover,
	&:focus {
		outline: 0;
		color: get-theme-color(accent, 0,CSSVAR);
		text-decoration: none;
	}

	// Toggles
	.fa {
		position: absolute;
		top: 50%;
		margin-top: -10px;
		z-index: 5;
		display: inline-block;
	}
}
.carousel-control-prev {
	// // @include gradient-horizontal($start-color: rgba(0, 0, 0, 0.5), $end-color: rgba(0, 0, 0, 0.0001));
	.fa {
		left: 50%;
		margin-left: -10px;
	}
}

.carousel-control-next {
	// // @include gradient-horizontal($start-color: rgba(0, 0, 0, 0.0001), $end-color: rgba(0, 0, 0, 0.5));
	left: auto;
	right: 0;
	.fa {
		right: 50%;
		margin-right: -10px;
	}
}

// Scale up controls for tablets and up
@media #{$medium-up} {
	// Scale up the controls a smidge
	.carousel-control-prev,
	.carousel-control-next {
		.fas {
			width: $carousel-control-font-size * 1.5;
			height: $carousel-control-font-size * 1.5;
			margin-top: math.div($carousel-control-font-size, -2);
			font-size: $carousel-control-font-size * 1.5;
		}
	}

	.carousel-control-prev .fa {
		margin-left: math.div($carousel-control-font-size, -2);
	}

	.carousel-control-next .fa {
		margin-right: math.div($carousel-control-font-size, -2);
	}
}
