
.btn {
	white-space: nowrap;
	font-family: $font_ui;
	font-weight: $font_medium;
}

.btn.clear {
	color: var(--link-color-dark);
	background-color: transparent;
	border-color: transparent;
	&:hover {
		color: hsl(var(--link-color-dark-h),var(--link-color-dark-s),calc( var(-link-color-dark-l ) * .5 ));
	}
}

.btn-accent {
	background-color: get-theme-color('accent', -1, CSSVAR);
	&:hover {
		background-color: get-theme-color('accent', -3, CSSVAR);
	}
}

.btn.shadow {
	box-shadow: make-shadow(3, 'drop', #000, 0.4);
}

.btn.shadow:hover {
	box-shadow: make-shadow(1, 'drop', #000, 0.1);
}
