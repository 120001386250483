/*

Deprecated: Lorem Text Loader

It is often we need to have space held for a return of details or text.  

Markup:
<div class="max-w_40">
   <h1 class="lorem-loader font_3 m-y_3">&nbsp;</h1>
   <p class="m-y_2 font_0 lorem-loader">&nbsp;</p>
   <p class="m-y_2 font_0 lorem-loader">&nbsp;</p>
   <p class="m-y_2 font_0 lorem-loader">&nbsp;</p>
</div>

Styleguide Recipes.InteractiveExtras.Text Placeholder Loader


*/

/*

Aspect Ratio

To create an image placeholder use the lorem-loader plus an aspect of the intended image on th parent container while waiting for the image.The placeholder will be a 100% of the container so size the container properly. 

.aspect_8x10			- lock image to a 8 to 10 ratio
.aspect_1x1			- lock image to a 1 to 1 ratio
.aspect_2x1			- lock image to a 2 to 1 ratio
.aspect_3x2			- lock image to a 3 to 2 ratio   
.aspect_4x3			- lock image to a 4 to 3 ratio 
.aspect_16x9		- lock image to a 16 to 9 ratio 
.aspect_21x9		- lock image to a 21 to 9 ratio 
.aspect_4x1			- lock image to a 4 to 1 ratio 

Markup:
<div class="grid-w_2 max-w_20"  data-style="{{ modifier_class }}" >
   <div class="m-y_2 lorem-loader {{modifier_class}} ">&nbsp;</div>
</div>

Styleguide Recipes.InteractiveExtras.Placeholder Aspect Ratio
*/


@use "sass:math";
@use "sass:list";

// Aspect Ratio

.lorem-loader {
	width: 100%;
	overflow: hidden;
	color: #fff;
	background: linear-gradient(-45deg, get-theme-color(shade, 2, CSSVAR), get-theme-color(shade, 3, CSSVAR));
	background-size: 400% 400%;
	-webkit-animation: Gradient 3s ease infinite;
	-moz-animation: Gradient 3s ease infinite;
	animation: Gradient 3s ease infinite;
}

@-webkit-keyframes Gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@-moz-keyframes Gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@keyframes Gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
