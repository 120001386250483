$navbar_primary:get-theme-color(acc,0,CSSVAR);
$navbar_hover: get-theme-color(accent,0,CSSVAR);
$navbar_active: get-theme-color(secondary,2,CSSVAR);

@if($usingFontAwesome){	
	.icon-toggle_bars-times:before {
		content: '\f2d3';
		.collapsed>&,.collapsed & {      
			content: '\f0c9';
		}
	}
	.icon-toggle_plus-minus:before {
		content: '\f0fe';
		.show > &{
			content: '\f146';
		}
	}
	.icon-toggle_plus-minus-sub:before {
		@extend %icon-toggle-space;
		content: '\f068';
		.collapsed>&,.collapsed &{
			content: '\f067';
		}
	}
}
.navbar {
	font-family:  $font_accent;
}
.dropdown-menu{
	font-family:  $font_ui;
}
.nav-link {
	
	padding-left: $padding_2;
	padding-right: $padding_2;
}
.navbar-dark .navbar-nav .nav-link{color: inherit;}

.navbar-primary .dropdown-toggle:after {
	display: none;
}
.navbar-primary .nav-item .nav-link{
	color:inherit;
}

.navbar-primary .nav-item {
	&:hover, &:focus-visible{
		border-top-color: $navbar_hover;
		border-bottom-color: #fff;
		background-color: #fff;
		color:$navbar_primary;
	}
	@media #{$medium-up} {
		border-top: $border-width_3 $navbar_primary solid;
		border-bottom: $border-width_3 $navbar_primary solid;
		&:hover, &:focus-visible {
			border-top-color: $navbar_hover;
			border-bottom-color: #fff;
			background-color: #fff;
			color: $navbar_primary;

		}
	}
}
.navbar-primary .navbar-nav .nav-item {
	@media #{$large-up} {
		border-top: $border-width_3 $navbar_primary solid;
		border-bottom: $border-width_3 $navbar_primary solid;
		.nav-link{ 
			&, &:hover, &:focus-visible{
			color:inherit; }
		}
		&:hover, &:focus-visible{
			border-top-color: $navbar_hover;
			border-bottom-color: #fff;
			background-color: #fff;
			color: $navbar_primary;
			.nav-link{ 
				&, &:hover, &:focus-visible{
					color:inherit;
				 }	
			}
		}
	}
}
@media #{$large-up} {
	.collapse.block\:lg {
		display: block;
	}
}

.dropdown-item{
	color: get-theme-color(primary, -1, CSSVAR);
	&:hover, &:focus-visible {
		color: white;
		background-color: get-theme-color(accent,0,CSSVAR);
	}
}


.dropdown_column{
	min-width: 70vw;
}
@media #{$medium-up} {
.dropdown_column{
	min-width: 20rem;
}
}

// Active /*

.navbar-primary .navbar-nav .nav-item {
	&.active {
		border-top-color: $navbar_active;
		border-bottom-color: $navbar_active;
		background-color: $navbar_active;
		color: #fff;
		.nav-link{ 
			&.active {
				color:inherit;
				}	
		}
		&:hover{border-top-color: $navbar_hover; color:$navbar_primary}
	}
}