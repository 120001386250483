@if($usingFontAwesome){	
	.accordion {
		i.icon-toggle_plus-minus:before {
			content: "\f146";
		}
		.collapsed i.icon-toggle_plus-minus:before {
			content: "\f0fe";
		}
	}
}
