@if($usingFontAwesome) {
    %icon-toggle-space {
        margin: $margin_0 $margin_2;
    }


    %icon-toggle-space {
        margin: $margin_0 $margin_2;
    }


    /*
Is Active Toggle 

Many momment there are needs for toggles of icon when the parent is in the state of `#{$active-class-name}`.  Used in tree navs, dropdowns, and collapsing areas. 

.icon-toggle_plus-minus 					-  Toggle between plus and minus square
.icon-toggle_chevron-up-down				- 	Toggle a chevron pointing up and then down.
.icon-toggle_chevron-double-up-down 		-  Toggle the double chevron to point up when active
.icon-toggle_chevron-double-left-right		- Toggle chevron left and right
.icon-toggle_arrow-circle 					-  Toggle the arrow to have a circle when active.
.icon-toggle_bars-times 					-  Toggle between bars and times
.icon-toggle_check-sqaure					-  Check Marks toggle on and off
.icon-toggle_check-circle 					-  Check Marks toggle on and off
.icon-toggle_check 							-  Check Marks toggle on and off
 
Markup:
<ul data-style="{{ modifier_class }}" class="ul_none m_0 p_0">
	<li class="m-t_1 is-active_toggle-function c_black a:c_white flex p-x_3 p-y_2 a:bg_primary font_1 bg_shade-2 ">	
	<i class="flex_none m-r_5 font_3 fas fa-fw {{ modifier_class }}"></i>
			<span class="flex_auto ">FA Solid {{ modifier_class }}</span>
			<span class="flex_shrink m-l_auto w_20 self_end a:_block display_none"> #{$active-class-name} </span>
	</li>
	<li class="m-t_1 is-active_toggle-function c_black a:c_white flex p-x_3 p-y_2 a:bg_primary font_1 bg_shade-2 ">	
	<i class="flex_none m-r_5 font_3 far  fa-fw {{ modifier_class }}"></i>
			<span class="flex_auto ">FA Regular {{ modifier_class }}</span>
			<span class="flex_shrink m-l_auto w_20 self_end a:_block display_none"> #{$active-class-name} </span>
	</li>
	<li class="m-t_1 is-active_toggle-function c_black a:c_white flex p-x_3 p-y_2 a:bg_primary font_1 bg_shade-2 ">	
	<i class="flex_none m-r_5 font_3 fal fa-fw {{ modifier_class }}"></i>
			<span class="flex_auto">FA Light {{ modifier_class }}</span>
			<span class="flex_shrink m-l_auto w_20 self_end a:_block display_none"> #{$active-class-name} </span>
	</li>
</ul>


Styleguide Recipes.InteractiveExtras.ToggleIcons
*/

    .icon-toggle_bars-times:before {
        @extend %icon-toggle-space;
        content: '\f0c9';
        .#{$active-class-name} &,
        &.show,
        .show>&,
        .#{$active-class-name} > & {
            content: '\f00d';
        }
    }

    .icon-toggle_plus-minus:before {
        @extend %icon-toggle-space;
        content: '\f0fe';
        .#{$active-class-name} &,
        &.show,
        .show>&,
        .#{$active-class-name} > & {
            content: '\f146';
        }
    }

    .icon-toggle_chevron-up-down:before {
        @extend %icon-toggle-space;
        content: '\f106';
        .#{$active-class-name} &,
        .show &,
        .#{$active-class-name} > & {
            content: '\f107';
        }
    }

    .icon-toggle_chevron-double-up-down:before {
        @extend %icon-toggle-space;
        content: '\f103';
        .#{$active-class-name} &,
        .show &,
        .#{$active-class-name} > & {
            content: '\f325';
        }
    }

    .icon-toggle_chevron-double-left-right:before {
        @extend %icon-toggle-space;
        content: '\f324';
        .#{$active-class-name} &,
        .show &,
        .#{$active-class-name} > & {
            content: '\f323';
        }
    }


    .icon-toggle_arrow-circle:before {
        @extend %icon-toggle-space;
        content: '\f061';
        .#{$active-class-name} &,
        .show &,
        .#{$active-class-name} > & {
            content: '\f0a9';
        }
    }

    .icon-toggle_check-sqaure:before {
        @extend %icon-toggle-space;
        content: '\f0c8';
        .#{$active-class-name} &,
        .show &,
        .#{$active-class-name} > & {
            content: '\f14a';
        }
    }

    .icon-toggle_check-circle:before {
        @extend %icon-toggle-space;
        content: '\f111';
        .#{$active-class-name} &,
        .show &,
        .#{$active-class-name} > & {
            content: '\f058';
        }
    }

    .icon-toggle_check:before {
        @extend %icon-toggle-space;
        content: '';
        .#{$active-class-name} &,
        .show &,
        .#{$active-class-name} > & {
            content: '\f00c';
        }
    }
}
